<template>
  <b-row>
    <b-col
      cols="12"
      lg="10"
      class="mx-auto mb-8"
    >
      <b-row>
        <b-col
          v-for="article in categories"
          :key="article.title"
          cols="12"
          sm="6"
          md="4"
        >
          <b-card :title="article.title">
            <template>
              <b-avatar
                :icon="article.icon"
                rounded
                :color="article.avatarColor"
                variant="tonal"
              />
            </template>

            <b-card-text>
              <ul
                class="ps-6"
                style="list-style: disc ;"
              >
                <li
                  v-for="item in article.subCategories"
                  :key="item.title"
                  class="text-primary mb-2"
                >
                  <RouterLink
                    :to="{
                      name: 'pages-help-center-category-subcategory',
                      params: { category: article.slug, subcategory: item.slug },
                    }"
                  >
                    {{ item.title }}
                  </RouterLink>
                </li>
              </ul>

              <div class="mt-4">
                <RouterLink
                  :to="{
                    name: 'pages-help-center-category-subcategory',
                    params: { category: article.slug, subcategory: article.subCategories[0].slug },
                  }"
                  class="text-base font-weight-semibold"
                >
                  {{ totalArticles(article) }} articles
                </RouterLink>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: 'Instruments',
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  methods: {
    totalArticles(category) {
      return category?.subCategories.map(subCategory => subCategory.articles.length).reduce((partialSum, a) => partialSum + a, 0) || 0
    }
  }
}
</script>

<template>
  <b-row>
    <b-col
      cols="12"
      lg="10"
      class="mx-auto mb-8"
    >
      <b-row>
        <b-col
          v-for="article in articles"
          :key="article.title"
          cols="12"
          md="4"
        >
          <b-card
            flat
            border
          >
            <b-card-text class="text-center">
              <b-img
                :src="article.img"
                aspect-ratio="1"
                width="58"
                class="mx-auto"
              />

              <h6 class="text-h6 my-3">
                {{ article.title }}
              </h6>
              <p>
                {{ article.subtitle }}
              </p>

              <b-button
                size="small"
                variant="tonal"
                :to="{
                  name: 'pages-help-center-category-subcategory-article',
                  params: { category: 'getting-started', subcategory: 'account', article: 'changing-your-username' },
                }"
              >
                Read More
              </b-button>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: 'Instruments',
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
}
</script>

<template>
  <div>




    <div class="text-center">
      <h3>Support Center</h3>
      <b-card-sub-title class="pt-50">Hello, how can we help?</b-card-sub-title>
    </div>
  <hr>

  <b-alert v-if="error" show variant="danger" dismissible>
    <h5 class="text-danger mb-25">Redirected</h5>
    <small>{{ error }}</small> <small>Please try a different means of support.</small>
  </b-alert>

  <b-overlay :show="!setting">
    <b-card-group v-if="setting" columns>
      <b-card v-if="setting.tours.enabled" no-body class="border text-center mb-1">
        <b-card-body body-class="pb-1">
          <feather-icon icon="NavigationIcon" size="30" class="mb-1"/>
          <h4>Getting Started</h4>
          <b-card-text>Take a virtual through different site functionality.</b-card-text>
        </b-card-body>
        <b-card-footer footer-class="border-0 pt-0">
          <b-link :to="{name: 'support-getting-started'}" class="bg-light-primary btn">Show Tours</b-link>
        </b-card-footer>
      </b-card>
      <b-card v-if="setting.documentation.enabled" no-body class="border text-center mb-1">
        <b-card-body body-class="pb-1">
          <feather-icon icon="BookOpenIcon" size="30" class="mb-1"/>
          <h4>Documentation</h4>
          <b-card-text>The knowledge you seek…</b-card-text>
        </b-card-body>
        <b-card-footer footer-class="border-0 pt-0">
          <b-link :to="{name: 'support-docs'}" class="bg-light-primary btn">Seek Answers</b-link>
        </b-card-footer>
      </b-card>
      <b-card v-if="setting.tickets.enabled" no-body class="border text-center mb-1">
        <b-card-body body-class="pb-1">
          <feather-icon icon="FileTextIcon" size="30" class="mb-1"/>
          <h4>Support Ticket</h4>
          <b-card-text>Having an issue? Found a bug?</b-card-text>
        </b-card-body>
        <b-card-footer footer-class="border-0 pt-0">
          <b-link :to="{name: 'support-tickets'}" class="bg-light-primary btn btn-md">Let Us Know</b-link>
        </b-card-footer>
      </b-card>
      <b-card v-if="setting.landing.email" no-body class="border text-center mb-1">
        <b-card-body body-class="pb-1">
          <feather-icon icon="MailIcon" size="30" class="mb-1"/>
          <h4>Email</h4>
          <b-card-text>{{ setting.landing.email }}</b-card-text>
        </b-card-body>
      </b-card>
      <b-card v-if="setting.landing.phone" no-body class="border text-center mb-1">
        <b-card-body body-class="pb-1">
          <feather-icon icon="PhoneIcon" size="30" class="mb-1"/>
          <h4>Phone</h4>
          <b-card-text>{{ setting.landing.phone }}</b-card-text>
        </b-card-body>
      </b-card>
    </b-card-group>
  </b-overlay>
  </div>
</template>
<script>
import HelpCenterLandingArticlesOverview from './HelpCenterLandingArticlesOverview.vue';
import HelpCenterLandingKnowledgeBase from './HelpCenterLandingKnowledgeBase.vue';
import HelpCenterLandingFooter from './HelpCenterLandingFooter.vue';
import notify from '@/mixins/notify.mixin';
import {API, graphqlOperation} from 'aws-amplify';
import {getSetting} from './queries/landing';

export default {
  name: 'SupportLanding',
  components: {
    HelpCenterLandingArticlesOverview,
    HelpCenterLandingKnowledgeBase,
    HelpCenterLandingFooter
  },
  mixins: [ notify ],
  props: {
    error: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      setting: null,
      category: {
        id: null,
        slug: null,
        icon: 'fa-solid fa-',
        title: null,
        description: null,
      },
      categories: {
        items: [],
        loading: false,
      },
      document: {
        id: null,
        slug: null,
        categoryID: null,
        title: null,
        description: null,
        content: null,
        isPublished: false,
      },
    }
  },
  mounted() {
    this.getSetting()
  },
  methods: {
    async getSetting() {
      const response = await API.graphql(graphqlOperation(getSetting, { key: 'support' }));
      const setting = response.data.getSetting
      if(setting) {
        this.setting = JSON.parse(setting.value)
      }
      this.loading = false
    },
  }
}
</script>
